// pages/patient.js
import React from "react"
import { graphql　} from "gatsby"
import Layout from "../../components/layout";
import SEO from "../../components/seo"
import List from "../../components/list"

const ctgPage = ({ data, pageContext }) => (
   <Layout>
     <SEO title={pageContext.category} image={data.allMicrocmsPhoto.edges[0].node.list.url} />
     <div className="p_cat contents padding">
       <div className="p_page_head inner">
         <h1 className="p_page_head_ttl">{pageContext.category}</h1>
       </div>
       <List data={data.allMicrocmsPhoto} />
     </div>
   </Layout>
)


export const query = graphql`
 query($category: String!) {
  allMicrocmsPhoto(filter: {category: {category: {eq:$category}}}){
   edges {
     node {
      id
      slug
      category {
        category
      }
      list {
        url
      }
      subttl
      title
      photo {
        image {
          url
        }
      }
     }
   }
  }

 }
`

export default ctgPage